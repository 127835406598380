const sections = {
  ActionForm: "ContentfulActionForm",
  ActionFormSubmission: "ContentfulActionFormSubmission",
  ActionNavigate: "ContentfulActionNavigate",
  ActionOpenVideo: "ContentfulActionOpenVideo",
  ActionRedirect: "ContentfulActionRedirect",
  CallToAction: "ContentfulCallToAction",
  ContentfulSectionRichText: "ContentfulSectionRichText",
  DynamicSection: "ContentfulSectionDynamic",
  Footer: "ContentfulFooter",
  Form: "ContentfulSectionForm",
  Header: "ContentfulHeader",
  Headline: "ContentfulHeadline",
  HeroVideo: "ContentfulSectionHeroVideo",
  ListOfAccordionCards: "ContentfulSectionListOfAccordionCards",
  OrderedList: "ContentfulOrderedList",
  ParagraphsList: "ContentfulParagraphsList",
  SectionCards: "ContentfulSectionCards",
  SectionHeroPhoto: "ContentfulSectionHeroPhoto",
  SectionList: "ContentfulSectionList",
  SectionListOfActions: "ContentfulSectionListOfActions",
  SectionQuote: "ContentfulSectionQuote",
  SideBySide: "ContentfulSectionSideBySide",
  Subheader: "ContentfulSubheader",
  TemplateDetailsSection: "ContentfulTemplateSection",
  TextSection: "ContentfulSectionText",
  VideoWrapper: "ContentfulVideoWrapper",
  WrapperSection: "ContentfulSectionWrapper",
  FeatureCards: "ContentfulFeatureCards",
  MapSection: "ContentfulSectionMap",
  CenterFeatures: "ContentfulCenterFeatures",
}

export default sections
